function ContentMapper(injectedLogger) {
    const logger = injectedLogger || {
        debug() {
        },
    };

    this.map = (chefkochModel) => {
        if (chefkochModel.content && chefkochModel.content.type) {
            const content = {};

            let campaign = null;
            if (chefkochModel.content.type.indexOf('campaign') !== -1) {
                campaign = chefkochModel.content;
            } else if (chefkochModel.content.campaign) {
                campaign = chefkochModel.content.campaign;
            }

            logger.debug(`[gtm] Mapping ${chefkochModel.content.type}(-${chefkochModel.content.id}) on content_id`);
            content.content_id = `${chefkochModel.content.type}`;
            if (chefkochModel.content.id) {
                content.content_id += `-${chefkochModel.content.id}`;
            }
            content.content_type = `${chefkochModel.content.type}`;

            if (campaign) {
                content.ck_campaign_id = `${campaign.type}-${campaign.id}`;
            }
            if (chefkochModel.content.customerId) {
                content.content_owner_id = chefkochModel.content.customerId;
            }
            if (chefkochModel.variant) {
                content.dimension15 = chefkochModel.variant;
            }
            if (chefkochModel.ck_source) {
                content.ck_source = chefkochModel.ck_source;
            }
            if (chefkochModel.ck_element) {
                content.ck_element = chefkochModel.ck_element;
            }
            if (chefkochModel.ck_origin) {
                content.ck_origin = chefkochModel.ck_origin;
            }
            if (chefkochModel.login_status) {
                content.login_status = chefkochModel.login_status;
            }
            if (chefkochModel.user_entitlements) {
                content.user_entitlements = chefkochModel.user_entitlements;
            }
            if (chefkochModel.user_id) {
                content.user_id = chefkochModel.user_id;
            }
            if (chefkochModel.personalized) {
                content.dimension30 = chefkochModel.personalized;
            }
            return content;
        }
        return {};
    };
}

export default ContentMapper;
